import { useRouter } from "next/router"
import { useEffect, useState } from "react"

export const useActivePath = () => {
  const [activePath, setActivePath] = useState<string | null>(null)
  const { pathname } = useRouter()

  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/gallery" ||
      pathname === "/contact-us" ||
      pathname === "/partners"
    ) {
      setActivePath(`/`)
    } else if (
      pathname === "/perform" ||
      pathname === "/classes" ||
      pathname === "/all-trainers"
    ) {
      setActivePath("/perform")
    } else if (pathname === "/recover") {
      setActivePath(`/recover`)
    } else if (pathname === "/sleep") {
      setActivePath(`/sleep`)
    } else if (pathname === "/property") {
      setActivePath(`/property`)
    } else if (pathname === "/gym-memberships") {
      setActivePath(`/gym-memberships`)
    } else if (pathname === `/nutrition-shop` || pathname === `/cafe`) {
      setActivePath(`/nutrition-shop`)
    } else {
      setActivePath(null)
    }
  }, [pathname])

  return { activePath, pathname }
}
