import BiMenu from "@/public/icons/BiMenu.svg"
import IoIosClose from "@/public/icons/IoIosClose.svg"
import SportGymnasticsIcon from "@/public/icons/sports_gymnastics.svg"
import LogoIcon from "public/logo.svg"

import dynamic from "next/dynamic"
import Link from "next/link"
import { FC, useEffect, useRef, useState } from "react"
import { Link as ScrollLink } from "react-scroll"

import { useActivePath } from "@/lib/hooks/useActivePath"
import { cn } from "@/lib/utils"

import ArrowRightWhite from "public/icons/arrow-right-white.svg"
import ArrowRightYellow from "public/icons/arrow-right-yellow.svg"
import ChevronRightWhite from "public/icons/chevron_right-white.svg"
import ChevronRight from "public/icons/chevron_right.svg"
import ExpandMoreIcon from "public/icons/expand_more.svg"
import ClassesIcon from "public/icons/navbar/Classes.svg"
import CoachesIcon from "public/icons/navbar/Coaches.svg"
import ContactIcon from "public/icons/navbar/Contact.svg"
import GalleryIcon from "public/icons/navbar/Gallery.svg"
import MealPlanIcon from "public/icons/navbar/MealPlan.svg"
import PartnersIcon from "public/icons/navbar/Partners.svg"

const AuthDesktopNavbar = dynamic(
  () => import("@/components/AuthNavbar").then((mod) => mod.AuthDesktopNavbar),
  { ssr: false },
)
const AuthNavbarDrawer = dynamic(
  () => import("@/components/AuthNavbar").then((mod) => mod.AuthNavbarDrawer),
  { ssr: false },
)

const CartDesktopNavbar = dynamic(
  () => import("@/components/CartNavbar").then((mod) => mod.CartDesktopNavbar),
  { ssr: false },
)
const CartMobileNavbar = dynamic(
  () => import("@/components/CartNavbar").then((mod) => mod.CartMobileNavbar),
  { ssr: false },
)

const navLinks: {
  name: string
  href: string
  dataCy?: string
  openOutside?: boolean
  subLinks?: {
    Icon?: React.ReactNode
    name: string
    href: string
    dataCy?: string
    openOutside?: boolean
  }[]
}[] = [
  {
    name: "Home",
    href: "/",
    subLinks: [
      {
        Icon: <GalleryIcon className="w-[20px] h-[20px]" />,
        name: "Events",
        href: "/events",
        dataCy: "nav-events",
      },
      {
        Icon: <GalleryIcon className="w-[20px] h-[20px]" />,
        name: "Gallery",
        href: "/gallery",
        dataCy: "nav-gallery",
      },
      {
        Icon: <GalleryIcon className="w-[20px] h-[20px]" />,
        name: "Virtual tour",
        href: "https://my.matterport.com/show/?m=82Tt5Ku8xyA",
        openOutside: true,
        dataCy: "nav-virtual-tour",
      },
      {
        Icon: <ContactIcon className="w-[20px] h-[20px]" />,
        name: "Contact Us",
        href: "/contact-us",
        dataCy: "nav-contact-us",
      },
      {
        Icon: <PartnersIcon className="w-[20px] h-[20px]" />,
        name: "Partners",
        href: "/partners",
        dataCy: "nav-partners",
      },
    ],
  },
  {
    name: "Perform",
    href: "/perform",
    subLinks: [
      {
        Icon: <ClassesIcon className="w-[20px] h-[20px]" />,
        name: "Classes",
        href: "/classes",
        dataCy: "nav-classes",
      },
      {
        Icon: <CoachesIcon className="w-[20px] h-[20px]" />,
        name: "Coaches",
        href: "/all-trainers",
        dataCy: "nav-coaches",
      },
      {
        Icon: <CoachesIcon className="w-[20px] h-[20px]" />,
        name: "Perform",
        href: "/perform",
        dataCy: "nav-perform",
      },
    ],
  },
  {
    name: "Recover",
    href: "/recover",
    dataCy: "nav-recover",
  },
  // {
  //   name: "Sleep",
  //   href: "/sleep",
  // },
  {
    name: "Accommodation",
    href: "/accommodation",
    dataCy: "nav-accommodation",
    subLinks: [
      {
        // Icon: <GalleryIcon className="w-[20px] h-[20px]" />,
        name: "Lifestyle Residence Canggu",
        href: "https://bodyfactorystays.com/",
        // dataCy: "nav-events",
        openOutside: true,
      },
      {
        // Icon: <GalleryIcon className="w-[20px] h-[20px]" />,
        name: "Boutique On-site Rooms",
        href: "/accommodation",
        // dataCy: "nav-gallery",
      },
    ],
  },
  {
    name: "Memberships",
    href: "/gym-memberships",
    dataCy: "nav-memberships",
  },
  {
    name: "Meal Plans",
    href: "/nutrition-shop",
    subLinks: [
      {
        Icon: <MealPlanIcon className="w-[20px] h-[20px]" />,
        name: "Meal Plans",
        href: "/nutrition-shop",
        dataCy: "nav-meal-plans",
      },
      {
        Icon: <MealPlanIcon className="w-[20px] h-[20px]" />,
        name: "Cafe",
        href: "/cafe",
        dataCy: "nav-cafe",
      },
    ],
  },
  {
    name: "Property",
    href: "/property",
    openOutside: false,
    dataCy: "nav-property",
  },
]
const NavbarV2: FC = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1280) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <header className="relative z-[101] flex items-stretch justify-between header-shadow px-8 text-white bg-text font-semibold text-sm uppercase border-b-brand ">
      <Link aria-label="Home" className="py-[20px]" href={"/"}>
        <LogoIcon className="h-[45px]" height={45} width={199} />
      </Link>

      {!isMobile ? <DesktopNavbar /> : <MobileNavbar />}
    </header>
  )
}

const MobileNavbar: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const { pathname } = useActivePath()

  if (pathname === "/get-started") {
    return (
      <div className="flex items-center xl:hidden">
        <ScrollLink
          // aria-label="Get Started with Perform"
          // href={isAuthenticated ? `gym-memberships` : "login"}
          className="bg-transparent  border-2 border-brand rounded-[10px] flex gap-[13px] items-center px-[18px] text-brand font-semibold capitalize py-[8px]"
          to="form"
          smooth
        >
          <span>Get started</span>
          <span>
            <SportGymnasticsIcon height={17} width={21} />
          </span>
        </ScrollLink>
      </div>
    )
  } else {
    return (
      <div className="flex items-center xl:hidden">
        <div className="flex items-center gap-[30px]">
          <CartMobileNavbar />
          <button
            aria-label="openMobileMenu"
            data-cy="openMobileMenu"
            onClick={open}
          >
            {isOpen ? (
              <IoIosClose
                height={40}
                width={40}
                className="p-0 leading-none text-brand"
              />
            ) : (
              <BiMenu
                height={40}
                width={40}
                className="p-0 leading-none text-brand"
              />
            )}
          </button>
        </div>
        <NavbarDrawer isOpen={isOpen} open={open} close={close} />
      </div>
    )
  }
}

const NavbarDrawer: FC<{
  isOpen: boolean
  close: () => void
  open: () => void
}> = ({ close, isOpen }) => {
  const { activePath } = useActivePath()

  const navbarRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!navbarRef.current) {
      return
    }
    const clickAwayListener = (e: MouseEvent) => {
      if (navbarRef.current?.contains(e.target as Node)) {
        return
      } else {
        close()
      }
    }

    const escapeListener = (ev: globalThis.KeyboardEvent) => {
      if (ev.key === "Escape") {
        close()
      }
    }
    window.addEventListener("mousedown", clickAwayListener)
    window.addEventListener("keydown", escapeListener)

    return () => {
      window.removeEventListener("mousedown", clickAwayListener)
      window.removeEventListener("keydown", escapeListener)
    }
  }, [navbarRef, close])

  useEffect(() => {
    const body = window.document.body
    const html = document.documentElement
    const nextContainer = window.document.querySelector("#__next")
    if (isOpen) {
      body.classList.add("lock")
      html.style.overflowY = "hidden"
      nextContainer?.classList.add("lock")
    } else {
      body.classList.remove("lock")
      nextContainer?.classList.remove("lock")
      html.style.overflowY = "auto"
    }
  }, [isOpen])
  return (
    <div
      className={cn(
        "h-full z-[10000] font-display  animate-drawer shadow-xl fixed right-0 overflow-y-scroll top-[85px] border-brand bottom-0 max-w-full w-[450px] bg-text pb-[100px] text-white ",
        isOpen ? "block" : "hidden",
      )}
      ref={navbarRef}
    >
      <div className="relative  mx-auto w-fit  px-[50px]  h-full max-h-[100vh]">
        <div className=" space-y-[10px]">
          {navLinks.map((link) => (
            <Link
              aria-label={link.name}
              className={cn(
                "flex gap-[23px] group py-[12px] px-[18px] hover:bg-brand focus:bg-brand rounded-[10px] hover:text-text items-center",
                activePath === link.href ? `bg-brand` : ``,
              )}
              key={link.href}
              href={link.href}
              onClick={close}
              target={link.openOutside ? "_blank" : ""}
              data-cy={link.dataCy}
            >
              <span>
                {activePath === link.href ? (
                  <ChevronRight />
                ) : (
                  <ChevronRightWhite />
                )}
              </span>
              <span>{link.name}</span>
            </Link>
          ))}
        </div>
        <div className="mt-[30px] spacey-[20px]">
          {navLinks.map((link) => {
            if (link.subLinks && link.subLinks?.length > 0) {
              return link.subLinks?.map(
                ({ Icon, href, openOutside, dataCy, name }) => (
                  <Link
                    aria-label={name}
                    className="flex my-[15px] items-center gap-[20px]"
                    key={href}
                    href={href}
                    onClick={close}
                    target={openOutside ? "_blank" : ""}
                    data-cy={dataCy}
                  >
                    {Icon}
                    <span>{name}</span>
                  </Link>
                ),
              )
            }
          })}
        </div>
        <AuthNavbarDrawer onClose={close} />
        <div className="w-full h-[100px]"></div>
      </div>
    </div>
  )
}

const DesktopNavbar: FC = () => {
  const { activePath, pathname } = useActivePath()
  const shouldShow = pathname !== "/get-started"

  return (
    <div className="hidden  xl:flex gap-[50px]">
      {shouldShow && (
        <nav className="flex gap-[10px] items-center">
          {navLinks.map((link) => {
            if (link.subLinks) {
              return <MenuWithSubmenu link={link} key={link.href} />
            } else {
              return (
                <Link
                  aria-label={link.name}
                  data-cy={link.dataCy}
                  className={cn(
                    `flex items-center h-full px-[20px] transition-all duration-150 ease-in-out hover:text-text  hover:h-[140%] hover:rounded-b-[10px] text-sm hover:bg-brand focus:text-text  focus:h-[140%] focus:rounded-b-[10px] focus:bg-brand`,
                    activePath === link.href
                      ? `text-text  h-[140%] rounded-b-[10px] bg-brand`
                      : ``,
                  )}
                  key={link.href}
                  href={link.href}
                  target={link.openOutside ? "_blank" : ""}
                >
                  {link.name}
                </Link>
              )
            }
          })}
        </nav>
      )}
      <div className="flex items-center gap-[20px]">
        {pathname === "/get-started" ? (
          <ScrollLink
            to="form"
            className="bg-transparent  border-2 border-brand rounded-[10px] flex gap-[13px] items-center px-[18px] text-brand font-semibold capitalize py-[8px]"
            smooth
          >
            <span>Get started</span>
            <span>
              <SportGymnasticsIcon height={17} width={21} />
            </span>
          </ScrollLink>
        ) : (
          <>
            <AuthDesktopNavbar />
            <CartDesktopNavbar />
          </>
        )}
      </div>
    </div>
  )
}

const MenuWithSubmenu: FC<{ link: (typeof navLinks)[0] }> = ({ link }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { activePath } = useActivePath()

  return (
    <div
      onMouseOver={() => {
        setIsOpen(true)
      }}
      onMouseLeave={() => {
        setIsOpen(false)
      }}
      className="relative h-full group"
    >
      <Link
        aria-label={link.name}
        // className="flex items-center h-full gap-2 font-display"
        data-cy={link.dataCy}
        // onFocus={(e) => {
        //   setIsOpen(true)
        // }}
        // onBlur={() => {
        //   setIsOpen(false)
        // }}
        className={cn(
          `flex flex-col justify-center items-center hover:text-text h-full px-[30px] hover:h-[120%] hover:rounded-b-[10px]  transition-all duration-150 ease-in-out hover:bg-brand focus:text-text  focus:rounded-b-[10px]  text-sm focus:bg-brand`,
          activePath === link.href
            ? `text-text  h-[120%] rounded-b-[10px] bg-brand`
            : ``,
        )}
        href={link.href}
      >
        <span className="group-hover:translate-y-[6px]">{link.name}</span>
        <span className={"hidden group-hover:block translate-y-[20px]"}>
          <ExpandMoreIcon />
        </span>
      </Link>
      {isOpen && (
        <div className="absolute left-0 -bottom-[85px]  z-[101]">
          <div className="min-w-full translate-y-[20px] text-white bg-text border-4 rounded-[22px] border-brand">
            <div className="relative flex px-[46px] py-[25px] justify-between gap-[70px] w-full">
              {link.subLinks!.map((sl) => {
                if (sl.openOutside) {
                  return (
                    <a
                      aria-label={sl.name}
                      target="_blank"
                      key={sl.href}
                      className="w-max flex  group/sub capitalize font-medium text-lg hover:text-brand items-center gap-[12px]"
                      href={sl.href}
                      // onClick={() => {
                      //   window.location.replace(sl.href)
                      // }}
                    >
                      <span>{sl.name}</span>
                      <span>
                        <ArrowRightWhite className="block group-hover/sub:hidden" />
                        <ArrowRightYellow className="hidden group-hover/sub:block" />
                      </span>
                    </a>
                  )
                }
                return (
                  <Link
                    aria-label={sl.name}
                    className="w-max flex group/sub capitalize font-medium text-lg hover:text-brand items-center gap-[12px]"
                    href={sl.href}
                    key={sl.href}
                  >
                    <span>{sl.name}</span>
                    <span>
                      <ArrowRightWhite className="block group-hover/sub:hidden" />
                      <ArrowRightYellow className="hidden group-hover/sub:block" />
                    </span>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavbarV2
